<i18n lang="json5">{
	ru: {
		title: 'Онлайн-касса Bookhara.uz',
	},
	uz: {
		title: 'Onlayn kassa Bookhara.uz',
	}
}</i18n>

<template>
	<div class="flex flex-col h-full">
		<template v-if="$route.name === 'home'">
			<div
				ref="home"
				class="px-4 pb-8 2xl:px-12 bg-my-blue-dark home"
				:class="bg"
			>
				<div class="lg:table w-full lg:h-screen">
					<div class="lg:table-row">
						<div class="lg:table-cell pt-4">
							<HeaderBox @toggleSidebar="isSidebarOpen = !isSidebarOpen" />
						</div>
					</div>
					<main class="lg:table-row">
						<div class="lg:table-cell h-full align-middle">
							<router-view />
						</div>
					</main>
				</div>
				<FooterBox />
			</div>
		</template>
		<template v-else>
			<div
				class="lg:min-h-0 lg:flex lg:flex-col lg:h-full"
				:class="{'min-h-screen': $route.name !== 'error'}"
			>
				<InnerPageHeaderBox
					:requestData="requestData"
					:offers="offers"
					@refreshPage="refreshKey = Date.now()"
					@toggleSidebar="isSidebarOpen = !isSidebarOpen"
				/>
				<main class="flex flex-col grow pb-8">
					<router-view :requestData="requestData" :key="refreshKey" />
				</main>
			</div>
			<InnerPageFooterBox />
		</template>
		<Sidebar
			:class="{open: isSidebarOpen}"
			@toggleSidebar="isSidebarOpen = !isSidebarOpen"
		/>

		<portal-target name="modal" class="relative z-[10001]" />
		<portal-target name="modal2" class="relative z-[10001]" />
		<portal-target name="modal3" class="relative z-[10001]" />
		<portal-target name="modal4" class="relative z-[10001]" />
		<portal-target name="modal5" class="relative z-[10001]" />
	</div>
</template>

<script>
import HeaderBox from '~/components/HeaderBox.vue';
import FooterBox from '~/components/FooterBox.vue';
import InnerPageHeaderBox from '~/components/InnerPageHeaderBox.vue';
import InnerPageFooterBox from '~/components/InnerPageFooterBox.vue';
import Sidebar from '~/components/Sidebar.vue';
export default {
	name: 'Root',
	components: {
		HeaderBox,
		InnerPageHeaderBox,
		FooterBox,
		InnerPageFooterBox,
		Sidebar,
	},
	head() {
		return {
			htmlAttrs: {
				lang: this.$i18n.locale
			},
			title: this.$t('title'),
			//meta: [
			//	{name: 'keywords', content: 'bookhara'},
			//	{name: 'description', content: 'bookhara'},
			//],
			script: [
				{
					type: 'text/javascript',
					innerHTML: `window.addEventListener("pageshow", function(event) {if (event.persisted) {window.location.reload()}})`
				}
			],
			__dangerouslyDisableSanitizers: ['script']
		}
	},
	data() {
		return {
			refreshKey: null,
			isSidebarOpen: false,
		}
	},
	methods: {
		async fetchSavedPassengers() {
			await this.$store.dispatch('user/getSavedPassengers')
		},
	},
	computed: {
		user() {
			return this.$store.getters['user/user']
		},
		bg() {
			return this.$route.query.tab ? `bg-${this.$route.query.tab}` : 'bg-avia'
		},
		offers() {
			return this.$store.getters['avia/offers']
		},
		airportsAndCitiesNames() {
			return this.$store.getters['avia/airportAndCitiesNames']
		},
		requestData() {
			if (
				['avia-offers', 'avia-booking-page'].includes(this.$route.name)
				&& /^([A-ZА-Я]{3}-[A-ZА-Я]{3}-\d{8}_)+[EBA]_\d_\d_\d$/.test(this.$route.params.aviaQuery)
			) {
				const query = this.$route.params.aviaQuery
				const serviceClass = query.slice(-7, -6)
				const adults = +query.slice(-5, -4)
				const children = +query.slice(-3, -2)
				const infants = +query.slice(-1)
				const directions = []
				query.match(/[A-ZА-Я]{3}-[A-ZА-Я]{3}-\d{8}_/g).forEach(direction => {
					const departure = direction.slice(0, 3)
					const arrival = direction.slice(4, 7)
					const year = direction.slice(8, 12)
					const month = direction.slice(12, 14)
					const day = direction.slice(14, 16)

					directions.push({
						arrivalAirport: this.airportsAndCitiesNames ? this.airportsAndCitiesNames[arrival]?.city_intl?.[this.$i18n.locale] || this.airportsAndCitiesNames[arrival]?.city_intl?.['en'] || arrival : arrival,
						arrivalAirportCode: arrival,
						date: `${day}.${month}.${year}`,
						dateISO: `${year}-${month}-${day}`,
						departureAirport: this.airportsAndCitiesNames ? this.airportsAndCitiesNames[departure]?.city_intl?.[this.$i18n.locale] || this.airportsAndCitiesNames[departure]?.city_intl?.['en'] || arrival : departure,
						departureAirportCode: departure,
					})
				})

				return {
					aviaQuery: query,
					adults: adults,
					children: children,
					infants: infants,
					numberOfPassengers: adults + children + infants,
					serviceClass: serviceClass,
					directions: directions
				}
			} else if (['train-offers', 'train-booking-page'].includes(this.$route.name)) {
				const query = this.$route.params.trainQuery.split('_')
				return {
					stationFrom: query[0],
					stationTo: query[1],
					departureDate: query[2],
					arrivalDate: query[3]
				}
			}
			return null
		},
	},
	async beforeCreate() {
		if (process.client) {
			const promises = []
			const insuranceToken = this.$cookies.get('insuranceToken')

			try {
				promises.push(this.$store.dispatch('avia/getAirportAndCitiesNames'))
				promises.push(this.$store.dispatch('common/getCurrencyRates'))

				if (!insuranceToken) {
					promises.push(
						this.$store.dispatch('insurance/getToken') // Временно отключено получение токена страхования пока api не заработает
					)
				}

				await Promise.all(promises)
			} catch (e) {
				if (!this.$axios.isAxiosError(e)) {
					this.$sentry?.captureException(e)
				}
			}
		}
	},
	async beforeMount() {
		const marketingChannel = this.$route.query['marketing_channel']

		if (marketingChannel === 'aviasales') {
			this.$cookies.set('marketing_channel', 'aviasales', {path: '/', maxAge: 2592000, sameSite: 'lax'}) // Кука хранится 30 дней

			if (localStorage) {
				if (this.$route.query['marker']) {
					localStorage.setItem('marketing_marker', this.$route.query['marker'])
				} else {
					localStorage.removeItem('marketing_marker')
				}
				if (this.$route.query['marketing_market']) {
					localStorage.setItem('marketing_market', this.$route.query['marketing_market'])
				} else {
					localStorage.removeItem('marketing_market')
				}
			}
		} else if (marketingChannel === 'become-vip') {
			this.$cookies.set('marketing_channel', 'become-vip', {path: '/', expires: new Date(Date.UTC(2025, 1, 28)), sameSite: 'lax'}) // 28 февраля истекает срок
		}

		if (this.$route.query['marketing_commission']) {
			this.$cookies.set('marketing_commission', this.$route.query['marketing_commission'], {path: '/', sameSite: 'lax'})
		}

		if (['aviasales_promo', 'aviasales_promo2', 'aviasales_promo3'].includes(this.$route.query['utm_source'])) { // Обнуляем комиссию, если пользователь пришел по промо ссылке
			this.$cookies.set('marketing_commission', '0', {path: '/', sameSite: 'lax'})
		}

		if (this.user) {
			await this.fetchSavedPassengers()
		}
	},
	watch: {
		$route() {
			this.isSidebarOpen = false
		},
		isSidebarOpen(isOpen) {
			if (isOpen) {
				document.body.classList.add('sidebar-open')
			} else {
				document.body.classList.remove('sidebar-open')
			}
		},
		async user(user) {
			if (user) {
				await this.$store.dispatch('user/getSavedPassengers')
			}
		}
	},
}
</script>

<style lang="scss" scoped>
.home {
	background:
		url('/design/pattern.png') center top repeat,
		url('/design/sky.jpg') center top / cover no-repeat;
}
.bg-avia {
	@media (min-width: 1024px) {
		background:
			url('/design/background-avia.jpg') center / cover no-repeat fixed,
			url('/design/background-insurance.jpg') 0 -1000px no-repeat,
			url('/design/background-transfer.jpg') 0 -1000px no-repeat,
			url('/design/background-train.jpg') 0 -1000px no-repeat;
	}
}
.bg-insurance {
	@media (min-width: 1024px) {
		background:
			url('/design/background-insurance.jpg') center / cover no-repeat fixed,
			url('/design/background-avia.jpg') 0 -1200px no-repeat,
			url('/design/background-transfer.jpg') 0 -1000px no-repeat,
			url('/design/background-train.jpg') 0 -1000px no-repeat;
	}
}
.bg-transfer {
	@media (min-width: 1024px) {
		background:
			url('/design/background-transfer.jpg') center / cover no-repeat fixed,
			url('/design/background-avia.jpg') 0 -1200px no-repeat,
			url('/design/background-insurance.jpg') 0 -1000px no-repeat,
			url('/design/background-train.jpg') 0 -1000px no-repeat;
	}
}
.bg-train {
	@media (min-width: 1024px) {
		background:
			url('/design/background-train.jpg') center / cover no-repeat fixed,
			url('/design/background-transfer.jpg') 0 -1000px no-repeat,
			url('/design/background-avia.jpg') 0 -1200px no-repeat,
			url('/design/background-insurance.jpg') 0 -1000px no-repeat;
	}
}
</style>
